import { useState, useEffect, useRef } from "preact/hooks";
import ClipboardButton from "./ClipboardButton.tsx"; // Import ClipboardButton component

interface CodeBlockProps {
  code: string;
  language: string; // Specifies the language for syntax highlighting (e.g., 'javascript', 'typescript', 'json')
  noCode?: boolean; // Optional prop to hide code block if true
  maxHeight?: string; // Optional prop to set max height, default to '50vh'
}

export default function CodeBlock({ code, language, noCode = false, maxHeight = "50vh" }: CodeBlockProps) {
  const codeRef = useRef<HTMLElement>(null);
  const [fontSize, setFontSize] = useState<string>("14px"); // Default font size

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = globalThis.innerWidth;

      // Apply responsive font size based on screen width
      if (screenWidth < 640) {
        setFontSize("12px");
      } else if (screenWidth < 768) {
        setFontSize("14px");
      } else if (screenWidth < 1024) {
        setFontSize("16px");
      } else {
        setFontSize("18px");
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    globalThis.addEventListener("resize", handleResize);

    return () => {
      // Cleanup event listener on component unmount
      globalThis.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div class="relative pt-0">
      {/* Clipboard Button to copy code to clipboard */}
      <ClipboardButton text={code} />

      {/* Conditionally render the code block if noCode is not true */}
      {!noCode && (
        <pre
          class="bg-black text-white overflow-auto scrollbar-always pt-0"
          style={{ fontSize, maxHeight }} // Set the maxHeight dynamically
        >
          <code ref={codeRef} class={`language-${language}`}>
            {code}
          </code>
        </pre>
      )}
    </div>
  );
}
